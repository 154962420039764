import { Session, getServerSession } from "next-auth";

import { options } from "@/app/api/auth/[...nextauth]/options";

export const isAuthorized = async () => {
    const session = await getServerSession(options);

    if (!session || !session.user) {
        return false;

    }

    return true;
}

export const getFullName = (user: Session["user"]) => {
    if (!user) return "No User";

    let name = "";
    if (user.first_name) name += user.first_name;
    if (user.last_name) name += ` ${user.last_name}`;

    return `${name}`;
}

export const getFullNameWithEmail = (user: Session["user"]) => {
    if (!user) return "No User";

    let ret = getFullName(user);
    if (user.email) ret += ` (${user.email})`;

    return `${ret}`;
}